<template>
  <div class="billing-and-contribution-container">
    <div class="tab-page-container">
      <div
          v-for="page in tabPageList"
          :key="page.component"
          :class="{ checked: page.component === tabPage.component }"
          @click="() => {
            if (tabPage.component === page.component)
              $refs.page.onRefresh();
            else
              tabPage.component = page.component;
          }"
      >
        {{ page.name }}
      </div>
    </div>

    <page-container class="billing-and-contribution-page-container">
      <component ref="page" :is="tabPage.component"/>
    </page-container>
  </div>
</template>

<script>
import Billing from '@/views/my/billing.vue';
import Contribution from '@/views/my/contribution.vue';

export default {
  name: 'billingAndContribution',
  components: { Contribution, Billing },
  data() {
    return {
      tabPageList: [
        { name: '佣金', component: 'billing', },
        { name: '贡献值', component: 'contribution', },
      ],

      tabPage: { component: 'billing' },
    };
  },
};
</script>


<style scoped lang="less">
.billing-and-contribution-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  @tab-page-container-height: 40px;

  .tab-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: @tab-page-container-height;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;
      font-size: 14px;
      color: #555;
      text-align: center;
      transition: all .33s;

      &.checked {
        position: relative;
        font-size: 16px;
        font-weight: bolder;
        color: @primary-color;
      }
    }
  }

  .billing-and-contribution-page-container {
    top: @tab-page-container-height;

  }
}
</style>