<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="nav-page-container">
      <billing/>
<!--      <billing-and-contribution/>-->
    </page-container>
  </div>
</template>

<script>
import Billing from '@/views/my/billing.vue';
import BillingAndContribution from '@/views/my/billingAndContribution.vue';

export default {
  name: 'billingPage',
  components: { BillingAndContribution, Billing },
};
</script>