<!-- 贡献值 -->
<template>
  <page-container class="contribution-container">
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <template #loading>
        {{ pagination.pageNum < 2 ? '' : '加载中...' }}
      </template>

      <!-- 更多列表加载 -->
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedTextRender()"
          @load="onLoad"
      >
        <div class="contribution-balance">当前贡献值: <span>{{ contribution }}</span></div>

        <div
            class="contribution-item"
            v-for="item of dataList"
        >
          <div
              class="contribution-title"
              :style="{ color: getColor(item) }"
          >
            {{ item.title }}
          </div>
          <div>
            <label>贡献值：</label>
            <span class="contribution-change" :style="{ color: getColor(item) }">{{ item.contributionChange }}</span>
          </div>
          <div v-if="item.amount">
            <label>应发金额：</label>
            <span style="font-size: 18px;color: #e93b3d;">￥{{ item.amount }}</span>
          </div>
          <div v-if="item.orderNo">
            <label>订单号：</label>
            <span>{{ item.orderNo }}</span>
          </div>
          <div>
            <label>创建时间：</label>
            <span>{{ item.createTime }}</span>
          </div>
          <div
              v-if="item.memo && item.title !== item.memo"
              class="memo-text"
          >
            {{ item.memo }}
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

  </page-container>
</template>

<script>
import { getUserContributionFlowBalance, getUserContributionFlow } from '@/api/user.js';

export default {
  name: 'contribution',
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      contribution: '',
    };
  },
  methods: {
    getColor({ contributionChange }) {
      if (contributionChange > 0) {
        return '#00b532';
      }
      if (contributionChange < 0) {
        return 'red';
      }
    },

    onLoad() {
      this.pagination.pageNum++;
      let {
        pagination: { pageNum: current, pageSize: size },
      } = this;

      getUserContributionFlowBalance().then(data => {
        this.contribution = data?.contribution;
      });

      return getUserContributionFlow({
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    finishedTextRender() {
      let { loading, finished, dataList } = this;
      if (!loading && finished) {
        return dataList.length ? '没有更多了' : '暂无相关数据';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contribution-container {
  .contribution-balance {
    padding: 5px 12px;
    font-size: 14px;
    color: #666;

    & > span {
      font-size: 18px;
      color: #00b532;
    }
  }


  .contribution-item {
    margin: 10px;
    padding: 10px 16px;
    color: #333;
    background-color: #fff;
    border-radius: 10px;

    .contribution-title {
      font-size: 16px;
      color: #333;
    }

    & > div {
      margin: 10px 0;
      font-size: 14px;
      color: #888;

      & > label {
        display: inline-block;
        width: 5.5em;
        color: #999;
      }

      .contribution-change {
        font-size: 18px;
      }

      &.memo-text {
        padding: 14px 0 0 0;
        color: #444;
        border-top: 1px solid #f1f1f1;
      }
    }
  }
}

</style>
